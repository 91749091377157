import { render, staticRenderFns } from "./CriticalitiesList.vue?vue&type=template&id=469e59df&scoped=true&"
import script from "./CriticalitiesList.vue?vue&type=script&lang=js&"
export * from "./CriticalitiesList.vue?vue&type=script&lang=js&"
import style0 from "./CriticalitiesList.vue?vue&type=style&index=0&id=469e59df&lang=scss&scoped=true&"
import style1 from "./CriticalitiesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469e59df",
  null
  
)

export default component.exports