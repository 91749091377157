import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { getPreviousMonthAndYear, getCurrentMonthAndYear } from '@core/utils/filter';

// Notification
import useNotifications from "@notifications"

export default function useCriticalitiesList() {

    // Use toast
	const { toastNotification } = useNotifications()
	const { month, year } = getCurrentMonthAndYear()

    const refCriticalityListTable = ref(null);

	const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    /* let tableColumns = [
		{ key: 'start_date', sortable: true, label: 'MES/AÑO',  ...styleColumns  },
        { key: 'state_name', sortable: true, label: 'ESTADO', ...styleColumns  },
        { key: 'contract_number', sortable: true, label: 'N° CONTRATO', ...styleColumns  },
        { key: 'provider_name', sortable: true, label: 'PROVEEDOR', thClass: styleColumns.thClass },
        { key: 'billing_level_score', sortable: true, label: 'NIVEL <br/> FACTURACIÓN', ...styleColumns  },
        { key: 'production_continuity_score', sortable: true, label: 'CONTINUIDAD <br/> PRODUCCIÓN', ...styleColumns  },
        { key: 'activity_risk_score', sortable: true, label: 'RIESGO <br/> ACTIVIDAD', ...styleColumns  },
        { key: 'service_frequency_score', sortable: true, label: 'CANTIDAD <br/> MANO DE OBRA', ...styleColumns  },
        { key: 'workforce_quantity_score', sortable: true, label: 'FRECUENCIA <br/> SERVICIO', ...styleColumns  },
        { key: 'total_score', sortable: true, label: 'PUNTAJE <br/> CRITICIDAD', ...styleColumns  },
        { key: 'criticality_name', sortable: true, label: 'NIVEL <br/> CRITICIDAD', ...styleColumns  },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns  }
    ]; */
    let tableColumns = [
		{ key: 'cycle_date', sortable: true, label: 'MES/AÑO',  ...styleColumns  },
        { key: 'state_name', sortable: true, label: 'ESTADO', ...styleColumns  },
        { key: 'contract_number', sortable: true, label: 'N° CONTRATO', ...styleColumns  },
        { key: 'provider_name', sortable: true, label: 'PROVEEDOR', thClass: styleColumns.thClass },
        { key: 'billing_level', sortable: true, label: 'NIVEL <br/> FACTURACIÓN', ...styleColumns  },
        { key: 'production_continuity', sortable: true, label: 'CONTINUIDAD <br/> PRODUCCIÓN', ...styleColumns  },
        { key: 'activity_risk', sortable: true, label: 'RIESGO <br/> ACTIVIDAD', ...styleColumns  },
        { key: 'workforce_quantity', sortable: true, label: 'CANTIDAD <br/> MANO DE OBRA', ...styleColumns  },
        { key: 'service_frequency', sortable: true, label: 'FRECUENCIA <br/> SERVICIO', ...styleColumns  },
        /* { key: 'total_score', sortable: true, label: 'PUNTAJE <br/> CRITICIDAD', ...styleColumns  }, */
        { key: 'criticality_name', sortable: true, label: 'NIVEL <br/> CRITICIDAD', ...styleColumns  },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns  }
    ];

    const perPage = ref(10);
    const totalCriticalities = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);

    const statesFilter = ref(null);
	const criticalityLevelsFilter = ref(null);
	const monthFilter = ref(month);
	const yearFilter = ref(year);

    const dataMeta = computed(() => {
        const localItemsCount = refCriticalityListTable.value ? refCriticalityListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCriticalities.value,
        }
    });

    const refetchData = () => {
        refCriticalityListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statesFilter, criticalityLevelsFilter, monthFilter, yearFilter], () => {
        refetchData();
    });

    const fetchCriticalities = (ctx, callback) => {
        store
            .dispatch('app-criticality/fetchCriticalities', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                states: statesFilter.value,
				criticalityLevels: criticalityLevelsFilter.value,
				month: monthFilter.value,
				year: yearFilter.value,
            })
            .then(response => {
                const { criticalities, total } = response.data;
                callback(criticalities);
                totalCriticalities.value = total;
            })
            .catch(() => {
				toastNotification('Error al obtener lista de criticidades', false)
            })
    };

    return {
        fetchCriticalities,
        tableColumns,
        perPage,
        currentPage,
        totalCriticalities,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCriticalityListTable,

        refetchData,

        // Extra Filters
		statesFilter,
		criticalityLevelsFilter,
		monthFilter,
		yearFilter
    }
}