<template>
	<b-sidebar
		id="add-evaluate-contracts-sidebar"
		:visible="isAddEvaluateContractsSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-add-evaluate-contracts-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					<strong>CARGA DE CONTRATOS A EVALUAR (EXCEL)</strong>
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<div>
				<b-link
					href="/plantillas/plantilla_importacion_criticidad.xlsx"
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					class="btn btn-sm btn-success ml-2 mt-2"
					download
				>
					DESCARGAR PLANTILLA
					<feather-icon
						class="cursor-pointer"
						icon="DownloadIcon"
						size="16"
						@click="hide"
					/>
				</b-link>
			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<b-form-group
						label="Año"
						label-for="year"
					>
						<b-form-input
							id="year"
							:value="formData.year"
							disabled
							readonly
						/>
					</b-form-group>

					<b-form-group
						label="Mes"
						label-for="month"
					>
						<b-form-input
							id="month"
							:value="formData.monthLabel"
							disabled
							readonly
						/>
					</b-form-group>

					<!-- Names -->
					<validation-provider
						#default="{ errors }"
						name="archivo"
						rules="required"
					>
						<!-- Avatar -->
						<b-form-group
							label="Archivo"
							label-for="excel-file"
						>
							<b-form-file
								id="excel-file"
								v-model="formData.file"
								:state="errors.length > 0 ? false:null"
								@change="onFileChange"
								accept=".xls, .xlsx"
								placeholder="Elija un archivo o suéltelo aquí ..."
								drop-placeholder="Suelta el archivo aquí ...."
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button-loading
							text="GUARDAR CAMBIOS"
							type="submit"
							:processing="processing"
						/>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import useNotifications from "@notifications"
import { getMonthByNumber } from "@core/utils/filter"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref } from "@vue/composition-api"
import { required } from "@validations"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import store from "@/store"
import moment from "moment"

export default {
	components: {
		BButtonLoading,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
		required
	},
	directives: {
		Ripple
	},
	model: {
		prop: "isAddEvaluateContractsSidebarActive",
		event: "update:is-add-evaluate-contracts-sidebar-active"
	},
	props: {
		isAddEvaluateContractsSidebarActive: {
			type: Boolean,
			required: true
		},
		yearFilter: {
			type: Number,
			default: null
		},
		monthFilter: {
			type: Number,
			default: null
		}
	},
	setup(props, { emit }) {
		// Use toast
		const { toastNotification, swalNotification } = useNotifications()

		const processing = ref(false)

		const blankData = {
			year: props.yearFilter,
			monthLabel: getMonthByNumber(props.monthFilter),
			month: props.monthFilter,
			file: null
		}

		const formData = ref(JSON.parse(JSON.stringify(blankData)))
		const resetuserData = () => {
			formData.value = JSON.parse(JSON.stringify(blankData))
		}

		const onSubmit = () => {
			processing.value = true

			const payload = {
				...formData.value
			}

			store
				.dispatch("app-criticality/uploadTemplate", payload)
				.then((response) => {
					emit("refetch-data")
					toastNotification(response.data.message)
					emit("update:is-add-evaluate-contracts-sidebar-active", false)
				})
				.catch((error) => {
					const message =
						error.response.data.message ||
						"Ha ocurrido un error al importar el archivo"

					toastNotification(message, false)
				})
				.finally(() => {
					processing.value = false
				})
		}

		const onFileChange = (e) => {
			const file = e.target.files[0]
			if (file) {
				formData.value.file = file
			} else {
				formData.value.file = null
			}
		}

		const { refFormObserver, getValidationState, resetForm } =
			formValidation(resetuserData)

		return {
			moment,

			formData,
			onFileChange,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,

			processing
		}
	}
}
</script>