<template>
	<div>
		<!-- Filters -->
		<criticalities-list-filters
			v-if="isOptionsLoaded"
			:options="options"
			:states-filter.sync="statesFilter"
			:criticality-levels-filter.sync="criticalityLevelsFilter"
			:month-filter.sync="monthFilter"
			:year-filter.sync="yearFilter"
		/>

		<criticalities-import-file
			:is-add-evaluate-contracts-sidebar-active.sync="isAddEvaluateContractsSidebarActive"
			:year-filter="yearFilter"
			:month-filter="monthFilter"
			@refetch-data="refetchData"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar proveedor o Nro de contrato"
								trim
							/>
							<!-- <b-button
								variant="success"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								@click="isAddEvaluateContractsSidebarActive = true"
							>
								<span class="text-nowrap">Importar Excel</span>
							</b-button>-->
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refCriticalityListTable"
				class="position-relative table-criticalities"
				:style="'height: inherit'"
				:items="fetchCriticalities"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(billing_level)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(production_continuity)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(activity_risk)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(service_frequency)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(workforce_quantity)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(total_score)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(criticality_name)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(cycle_date)="data">
					<b-link
						v-if="!data.item.locked"
						:to="{ name: 'criticalities-evaluate', params: { id: data.item.id } }"
						class="font-weight-bold d-block text-nowrap"
					>
						<b-badge variant="light-info">{{ data.item.month }}</b-badge>
					</b-link>
					<b-badge
						v-else
						variant="light-secondary"
					>
						<feather-icon icon="LockIcon" />
						{{ data.item.month }}
					</b-badge>
				</template>

				<template #cell(state_name)="data">
					<b-badge :variant="data.item.state_color">{{ data.item.state_name }}</b-badge>
				</template>

				<template #cell(contract_number)="data">
					<b-link
						:to="{ name: 'contracts-edit', params: { id: data.item.contract_id } }"
						class="font-weight-bold d-block text-nowrap"
					>{{ data.item.contract_number }}</b-link>
				</template>

				<template #cell(provider_name)="data">
					<b-link
						:to="{ name: 'providers-edit', params: { id: data.item.provider_id } }"
						class="font-weight-bold d-block text-nowrap"
					>{{ data.item.provider_name }}</b-link>
				</template>

				<template #cell(billing_level)="data">
					<div
						class="text-nowrap"
						:class="{ 'text-right': data.item.billing_level }"
					>{{data.item.billing_level ? formatCurrency(parseFloat(data.item.billing_level), true) : '-'}}</div>
				</template>

				<template #cell(production_continuity)="data">
					<div class="text-nowrap">{{ data.item.production_continuity || '-'}}</div>
				</template>

				<template #cell(activity_risk)="data">
					<div class="text-nowrap">{{ data.item.activity_risk || '-'}}</div>
				</template>

				<template #cell(service_frequency)="data">
					<div class="text-nowrap">{{ data.item.service_frequency || '-'}}</div>
				</template>

				<template #cell(workforce_quantity)="data">
					<div class="text-nowrap">{{ data.item.workforce_quantity || '-'}}</div>
				</template>

				<template #cell(total_score)="data">
					<div
						class="text-nowrap"
						:class="{ 'text-primary font-weight-bold': data.item.total_score}"
					>{{ data.item.total_score || '-'}}</div>
				</template>

				<template #cell(criticality_name)="data">
					<b-badge :variant="data.item.criticality_color">{{ data.item.criticality_name || '-' }}</b-badge>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'criticalities-evaluate', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Evaluar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalCriticalities"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import useNotifications from "@notifications"
import { formatStringToDate, formatCurrency } from "@core/utils/filter"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import CriticalitiesListFilters from "./CriticalitiesListFilters.vue"
import CriticalitiesImportFile from "./CriticalitiesImportFile.vue"
import criticalityStoreModule from "../criticalityStoreModule"
import useCriticalitiesList from "./useCriticalitiesList"
import { avatarText } from "@core/utils/filter"
import VueSweetalert2 from "vue-sweetalert2"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		CriticalitiesListFilters,
		CriticalitiesImportFile,
		vSelect
	},
	directives: {
		Ripple
	},
	setup() {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()

		// REFS
		const options = ref({})
		const isOptionsLoaded = ref(false)
		const isAddEvaluateContractsSidebarActive = ref(false)

		const CRITICALITY_APP_STORE_MODULE_NAME = "app-criticality"

		// REGISTER MODULE
		if (!store.hasModule(CRITICALITY_APP_STORE_MODULE_NAME))
			store.registerModule(
				CRITICALITY_APP_STORE_MODULE_NAME,
				criticalityStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(CRITICALITY_APP_STORE_MODULE_NAME))
				store.unregisterModule(CRITICALITY_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const [levels, states, months] = await Promise.all([
					axios.get("/selectors/criticality-levels"),
					axios.get("/selectors/states"),
					axios.get("/selectors/months")
				])
				options.value = {
					...levels.data,
					...states.data,
					...months.data
				}
				isOptionsLoaded.value = true
			} catch (error) {
				toastNotification("Error al cargar datos de selección", false)
			}
		}

		const {
			fetchCriticalities,
			tableColumns,
			perPage,
			currentPage,
			totalCriticalities,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refCriticalityListTable,
			refetchData,

			// EXTRA FILTERS
			statesFilter,
			criticalityLevelsFilter,
			monthFilter,
			yearFilter
		} = useCriticalitiesList()

		return {
			options,
			isOptionsLoaded,
			isAddEvaluateContractsSidebarActive,

			fetchCriticalities,
			tableColumns,
			perPage,
			currentPage,
			totalCriticalities,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refCriticalityListTable,
			refetchData,

			// EXTRA FILTERS
			statesFilter,
			criticalityLevelsFilter,
			monthFilter,
			yearFilter,

			// ACTIONS
			avatarText,

			formatStringToDate,
			formatCurrency
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>